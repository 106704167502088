// Testimonials.js
import React from 'react';
import styles from './testimonial.module.css';

const testimonialsData = [
  {
    id: 1,
    name: "Tina (Xynergi)",
    description: "Salah satu mitra kami yang paling oke. Bikin toko saya makin cuan.",
    imageUrl: "/img/common/testi1.png",
  },
  {
    id: 2,
    name: "Marcell (Land Production-Printing)",
    description: "Timnya selalu fast respond! Harganya terbaik. Selalu mudah beli barang di Jooalan. Puas banget.",
    imageUrl: "/img/common/testi2.png",
  },
  {
    id: 3,
    name: "Putri (Mahasiswi)",
    description: "#KelasCuan oke banget. Diajarin banget buka toko online dari awal sampai bisa langsung jualan. Beda dari kelas yang lain. Worth it!",
    imageUrl: "/img/common/testi3.png",
  },
];

const Testimonials = () => {
  return (
    <section className={styles.testimonials}>
      <h2 className="text-3xl font-bold text-center mb-12">Testimoni Jooalan</h2>
      <div className={styles.testimonialCards}>
        {testimonialsData.map((testimonial) => (
          <div key={testimonial.id} className={styles.card}>
            <img src={testimonial.imageUrl} alt={testimonial.name} className="h-20 w-20 rounded-full mx-auto mb-4"/>
            <p>{testimonial.description}</p>
            <div className={styles.footer}>
              <div>
                <h4><b>{testimonial.name}</b></h4>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.indicator}>
        {/* Add your indicator logic here */}
      </div>
    </section>
  );
};

export default Testimonials;